import React from 'react';
import AdoptionBreadcumb from '../components/adoptions/AdoptionBreadcumb';
import AdoptionSlider from '../components/adoptions/AdoptionSlider'

function AdoptionsPages() {

  return (

		  <main>
		 
		  <AdoptionBreadcumb/>
		  <AdoptionSlider/>
		  {/* <Appoinment/> */}
		  {/* <AdoptionPuppies/>
		  <AdoptionFaq/>
		  <AdoptionGallery/> */}
		  
		  </main>

  )
}

export default AdoptionsPages;
